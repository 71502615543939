import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header border-0 pt-6" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "d-flex align-items-center position-relative my-1" }
const _hoisted_5 = { class: "svg-icon svg-icon-1 position-absolute ms-6" }
const _hoisted_6 = { class: "card-toolbar" }
const _hoisted_7 = {
  class: "d-flex justify-content-end",
  "data-kt-customer-table-toolbar": "base"
}
const _hoisted_8 = {
  type: "button",
  class: "btn btn-primary",
  "data-bs-toggle": "modal",
  "data-bs-target": "#kt_modal_add_enumeration"
}
const _hoisted_9 = { class: "svg-icon svg-icon-2" }
const _hoisted_10 = { class: "card-body pt-0" }
const _hoisted_11 = { class: "d-flex flex-column" }
const _hoisted_12 = { class: "text-start fs-4 fw-normal text-dark" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = ["onClick"]
const _hoisted_15 = {
  key: 0,
  class: "text-gray-600 d-flex align-items-center p-4 pt-2 flex-wrap border border-top-0 border-secondary rounded-bottom"
}
const _hoisted_16 = ["onClick"]
const _hoisted_17 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Datatable = _resolveComponent("Datatable")!
  const _component_EditEnumerationModal = _resolveComponent("EditEnumerationModal")!
  const _component_EditEnumerationValueModal = _resolveComponent("EditEnumerationValueModal")!
  const _component_AddEnumerationModal = _resolveComponent("AddEnumerationModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen021.svg" })
            ]),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
              onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchItems())),
              class: "form-control form-control-solid w-250px ps-15",
              placeholder: "Search"
            }, null, 544), [
              [_vModelText, _ctx.search]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("button", _hoisted_8, [
              _createElementVNode("span", _hoisted_9, [
                _createVNode(_component_inline_svg, { src: "media/icons/duotune/Navigation/Plus.svg" })
              ]),
              _createTextVNode(" Add Meta data ")
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_Datatable, {
          loading: _ctx.loading,
          "table-data": _ctx.tableData,
          "table-header": _ctx.tableHeader,
          "enable-items-per-page-dropdown": true
        }, {
          "cell-enumeration": _withCtx(({ row: enumerations }) => [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", {
                class: _normalizeClass(["d-flex w-100 justify-content-between align-items-center bg-light p-4 rounded-top border border-secondary", {
								'rounded-bottom': !enumerations.enumerationValues.length
							}])
              }, [
                _createElementVNode("div", _hoisted_12, _toDisplayString(enumerations.enumName), 1),
                _createElementVNode("div", null, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "btn btn-primary btn-sm me-3",
                    "data-bs-toggle": "modal",
                    "data-bs-target": "#kt_modal_edit_enumeration",
                    onClick: ($event: any) => (_ctx.editEnumeration(enumerations))
                  }, " Edit ", 8, _hoisted_13),
                  _createElementVNode("button", {
                    type: "button",
                    class: "btn btn-danger btn-sm",
                    onClick: ($event: any) => (_ctx.deleteEnumeration(enumerations.id))
                  }, " Delete ", 8, _hoisted_14)
                ])
              ], 2),
              (enumerations.enumerationValues.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(enumerations.enumerationValues, (item, i) => {
                      return (_openBlock(), _createElementBlock("span", {
                        key: i,
                        class: "badge bg-light text-gray-700 px-4 py-3 me-2 mt-2 d-flex align-items-center"
                      }, [
                        _createElementVNode("span", {
                          role: "button",
                          "data-bs-toggle": "modal",
                          class: "svg-icon svg-icon-3 me-2",
                          "data-bs-target": "#kt_modal_edit_enumeration-value",
                          onClick: ($event: any) => (_ctx.editEnumerationValue(item, enumerations.id))
                        }, [
                          _createVNode(_component_inline_svg, { src: "media/icons/duotune/art/art005.svg" })
                        ], 8, _hoisted_16),
                        _createTextVNode(" " + _toDisplayString(item.enumValue) + " ", 1),
                        _createElementVNode("span", {
                          type: "button",
                          class: "svg-icon svg-icon-3 my-auto ms-2",
                          onClick: ($event: any) => (_ctx.deleteEnumerationValue(item.id))
                        }, [
                          _createVNode(_component_inline_svg, { src: "media/icons/duotune/Home/Trash.svg" })
                        ], 8, _hoisted_17)
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["loading", "table-data", "table-header"])
      ])
    ]),
    _createVNode(_component_EditEnumerationModal, {
      customerId: _ctx.selectedCustomer.toString(),
      onGetEnumerationDetails: _ctx.getEnumerationsList,
      enumerationDetails: _ctx.selectedEnumeration,
      enumValues: _ctx.selectedEnumeration?.enumerationValues
    }, null, 8, ["customerId", "onGetEnumerationDetails", "enumerationDetails", "enumValues"]),
    _createVNode(_component_EditEnumerationValueModal, {
      onGetEnumerationDetails: _ctx.getEnumerationsList,
      selectedEnumerationValue: _ctx.selectedEnumerationValue
    }, null, 8, ["onGetEnumerationDetails", "selectedEnumerationValue"]),
    _createVNode(_component_AddEnumerationModal, {
      selectedCustomer: _ctx.selectedCustomer.toString(),
      onGetEnumerationsList: _ctx.getEnumerationsList
    }, null, 8, ["selectedCustomer", "onGetEnumerationsList"])
  ], 64))
}