
import { defineComponent, ref } from 'vue';
import { hideModal } from '@/core/helpers/dom';
import ApiService from '@/core/services/ApiService';
import Swal from 'sweetalert2/dist/sweetalert2.js';

export default defineComponent({
	name: 'add-enumeration-modal',
	props: {
		selectedCustomer: String
	},
	components: {},
	setup(props, { emit }) {
		const formRef = ref<null | HTMLFormElement>(null);
		const addEnumerationModalRef = ref<null | HTMLElement>(null);
		const loading = ref<boolean>(false);
		const selectedCustomer = ref(props.selectedCustomer);
		const formData = ref({
			sortOrder: 0,
			enumName: '',
			customerId: selectedCustomer.value
		});

		const rules = ref({
			enumName: [
				{
					required: true,
					message: 'Meta data name is required',
					trigger: 'change'
				}
			],
			sortOrder: [
				{
					required: true,
					message: 'Sort Order is required',
					trigger: 'change'
				}
			]
		});

		const submit = () => {
			if (!formRef.value) {
				return;
			}

			formRef.value.validate(valid => {
				if (valid) {
					loading.value = true;
					const data: any = formData.value;
					ApiService.post('Enumeration', data)
						.then(({ data }) => {
							console.log('create Enumeration response', data);
							loading.value = false;
							emit('getEnumerationsList');
							Swal.fire({
								timer: 1500,
								text: 'Form has been successfully submitted!',
								icon: 'success',
								buttonsStyling: false,
								confirmButtonText: 'Ok, got it!',
								customClass: {
									confirmButton: 'btn btn-primary'
								}
							}).then(() => {
								hideModal(addEnumerationModalRef.value);
								(document.getElementById('formRef') as HTMLFormElement).reset();
							});
						})
						.catch(({ response }) => {
							console.log('create enumeration error', response);
							Swal.fire({
								text: 'Sorry, looks like there are some errors detected, please try again.',
								icon: 'error',
								buttonsStyling: false,
								confirmButtonText: 'Ok, got it!',
								customClass: {
									confirmButton: 'btn btn-primary'
								}
							});
						});
				} else {
					Swal.fire({
						text: 'Sorry, looks like there are some errors detected, please try again.',
						icon: 'error',
						buttonsStyling: false,
						confirmButtonText: 'Ok, got it!',
						customClass: {
							confirmButton: 'btn btn-primary'
						}
					});
					return false;
				}
			});
		};

		return {
			formData,
			rules,
			submit,
			formRef,
			loading,
			addEnumerationModalRef
		};
	}
});
