
import { useStore } from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {
	defineComponent,
	ref,
	reactive,
	computed,
	watch,
	onMounted
} from 'vue';
// @import utils
import ApiService from '@/core/services/ApiService';
import { IEnumeration } from '@/core/data/enumerations';
import { reinitializeComponents } from '@/core/plugins/keenthemes';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
// @import components
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import AddEnumerationModal from '@/components/modals/forms/AddEnumerationModal.vue';
import EditEnumerationModal from '@/components/modals/forms/EditEnumerationModal.vue';
import EditEnumerationValueModal from '@/components/modals/forms/EditEnumerationValueModal.vue';

export default defineComponent({
	name: 'enumeration-listing',
	components: {
		Datatable,
		AddEnumerationModal,
		EditEnumerationModal,
		EditEnumerationValueModal
	},
	setup() {
		const loading = ref(true);
		const store = useStore();
		const tableHeader = ref([
			{
				name: '',
				key: 'enumeration',
				sortable: false
			}
		]);

		const selectedEnumeration = ref({
			id: ''
		});

		const selectedEnumerationValue = reactive({
			id: 0,
			sortOrder: 0,
			enumValue: '',
			enumerationId: ''
		});

		const tableData = ref<Array<IEnumeration>>([]);
		const initialData = ref<Array<IEnumeration>>([]);

		const selectedCustomer = computed(() => {
			return store.getters.selectedCustomer;
		});

		onMounted(() => {
			setCurrentPageBreadcrumbs('Meta data', []);
			getEnumerationsList();
		});

		const getEnumerationsList = () => {
			ApiService.get('EnumerationValues/Nested')
				.then(({ data }) => {
					// console.log('getEnumerationsList response: ', data);
					let filteredData = data.filter(item => {
						return item.customerId == selectedCustomer.value;
					});

					if (selectedEnumeration.value.id) {
						const selectedItem = filteredData.find(
							item => item.id === selectedEnumeration.value.id
						);
						editEnumeration(selectedItem);
					}

					if (store.getters.currentUser.role.toLowerCase() !== 'admin') {
						filteredData = filteredData.filter(
							item =>
								item.enumName?.toLowerCase() !== 'cssclasses' &&
								item.enumName?.toLowerCase() !== 'csstemplate'
						);
					}
					tableData.value.splice(0, tableData.value.length, ...filteredData);
					initialData.value.splice(0, tableData.value.length, ...filteredData);

					loading.value = false;
					reinitializeComponents();
				})
				.catch(({ response }) => {
					console.log('response', response);
				});
		};

		const editEnumeration = enumeration => {
			const enumerationId = enumeration.id;
			selectedEnumeration.value = {
				...enumeration,
				enumerationId: enumerationId
			};
		};

		const editEnumerationValue = (item, enumerationId) => {
			selectedEnumerationValue.id = item.id;
			selectedEnumerationValue.enumValue = item.enumValue;
			selectedEnumerationValue.sortOrder = item.sortOrder;
			selectedEnumerationValue.enumerationId = enumerationId;
		};

		const deleteEnumeration = id => {
			Swal.fire({
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				buttonsStyling: false,
				showCancelButton: true,
				confirmButtonText: 'Yes, delete it!',
				customClass: {
					confirmButton: 'btn btn-danger',
					cancelButton: 'btn btn-secondary'
				}
			}).then(result => {
				if (result.isConfirmed) {
					deleteData(id);
				}
			});
		};

		const deleteData = id => {
			ApiService.delete(`Enumeration/${id}`)
				.then(({ data }) => {
					console.log('data', data);
					getEnumerationsList();
					Swal.fire({
						timer: 1500,
						text: 'Data has been successfully deleted!',
						icon: 'success',
						buttonsStyling: false,
						confirmButtonText: 'Ok, got it!',
						customClass: {
							confirmButton: 'btn btn-primary'
						}
					});
				})
				.catch(({ response }) => {
					console.log('response', response);
					Swal.fire({
						text: 'Sorry, looks like there are some errors detected, please try again.',
						icon: 'error',
						buttonsStyling: false,
						confirmButtonText: 'Ok, got it!',
						customClass: {
							confirmButton: 'btn btn-primary'
						}
					});
				});
		};

		const deleteEnumerationValue = id => {
			Swal.fire({
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				buttonsStyling: false,
				showCancelButton: true,
				confirmButtonText: 'Yes, delete it!',
				customClass: {
					confirmButton: 'btn btn-danger',
					cancelButton: 'btn btn-secondary'
				}
			}).then(result => {
				if (result.isConfirmed) {
					deleteEValueData(id);
				}
			});
		};

		const deleteEValueData = id => {
			ApiService.delete(`EnumerationValues/${id}`)
				.then(({ data }) => {
					getEnumerationsList();
					console.log('data', data);
					getEnumerationsList();
					Swal.fire({
						timer: 1500,
						text: 'Data has been successfully deleted!',
						icon: 'success',
						buttonsStyling: false,
						confirmButtonText: 'Ok, got it!',
						customClass: {
							confirmButton: 'btn btn-primary'
						}
					});
				})
				.catch(({ response }) => {
					console.log('response', response);
					Swal.fire({
						text: 'Sorry, looks like there are some errors detected, please try again.',
						icon: 'error',
						buttonsStyling: false,
						confirmButtonText: 'Ok, got it!',
						customClass: {
							confirmButton: 'btn btn-primary'
						}
					});
				});
		};

		const search = ref<string>('');
		const searchItems = () => {
			tableData.value.splice(0, tableData.value.length, ...initialData.value);
			if (search.value !== '') {
				let results: Array<IEnumeration> = [];
				for (let j = 0; j < tableData.value.length; j++) {
					if (searchingFunc(tableData.value[j], search.value)) {
						results.push(tableData.value[j]);
					}
				}
				tableData.value.splice(0, tableData.value.length, ...results);
			}
		};

		const searchingFunc = (obj, value): boolean => {
			for (let key in obj) {
				if (
					!Number.isInteger(obj[key]) &&
					!(typeof obj[key] === 'object') &&
					!(typeof obj[key] === 'boolean')
				) {
					if (obj[key].indexOf(value) != -1) {
						return true;
					}
				}
			}
			return false;
		};

		return {
			loading,
			tableData,
			tableHeader,
			selectedCustomer,
			deleteEnumeration,
			search,
			getEnumerationsList,
			searchItems,
			editEnumeration,
			editEnumerationValue,
			deleteEnumerationValue,
			selectedEnumeration,
			selectedEnumerationValue
		};
	}
});
