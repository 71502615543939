
import { defineComponent, ref, computed } from 'vue';
import { hideModal } from '@/core/helpers/dom';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import ApiService from '@/core/services/ApiService';
export default defineComponent({
	name: 'edit-enumeration-modal',
	props: {
		customerId: String,
		enumerationDetails: Object,
		enumValues: Array
	},
	components: {},
	setup(props, { emit }) {
		const formRef = ref<null | HTMLFormElement>(null);
		const valuesFormRef = ref<null | HTMLFormElement>(null);
		const editenumerationModalRef = ref<null | HTMLElement>(null);
		const loading = ref<boolean>(false);
		const valueLoading = ref<boolean>(false);
		// const formData = ref(props.enumerationDetails);
		const formData = computed(() => {
			return props.enumerationDetails;
		});
		const enumValueData = computed(() => {
			return props.enumValues;
		});
		const formValueData = ref({
			enumValue: ''
		});

		const rules = ref({
			enumerationName: [
				{
					required: true,
					message: 'Meta data name is required',
					trigger: 'change'
				}
			],
			customerId: [
				{
					required: true,
					message: 'Customer ID is required',
					trigger: 'change'
				}
			],
			sortOrder: [
				{
					required: true,
					message: 'Sort Order is required',
					trigger: 'change'
				}
			]
		});

		const valuesRules = ref({
			enumValue: [
				{
					required: true,
					message: 'Meta data value is required',
					trigger: 'change'
				}
			]
		});

		const submit = () => {
			if (!formRef.value) {
				return;
			}

			formRef.value.validate(valid => {
				if (valid) {
					loading.value = true;
					const data: any = formData.value;
					ApiService.put(
						`Enumeration/${props.enumerationDetails?.id}?enumerationId=${props.enumerationDetails?.id}`,
						data
					)
						.then(({ data }) => {
							console.log('edit enumeration response', data);
							loading.value = false;
							emit('getEnumerationDetails');
							Swal.fire({
								timer: 1500,
								text: 'Form has been successfully submitted!',
								icon: 'success',
								buttonsStyling: false,
								confirmButtonText: 'Ok, got it!',
								customClass: {
									confirmButton: 'btn btn-primary'
								}
							}).then(() => {
								hideModal(editenumerationModalRef.value);
							});
						})
						.catch(({ response }) => {
							console.log('create enumeration error', response);
							Swal.fire({
								text: 'Sorry, looks like there are some errors detected, please try again.',
								icon: 'error',
								buttonsStyling: false,
								confirmButtonText: 'Ok, got it!',
								customClass: {
									confirmButton: 'btn btn-primary'
								}
							});
						});
				} else {
					Swal.fire({
						text: 'Sorry, looks like there are some errors detected, please try again.',
						icon: 'error',
						buttonsStyling: false,
						confirmButtonText: 'Ok, got it!',
						customClass: {
							confirmButton: 'btn btn-primary'
						}
					});
					return false;
				}
			});
		};

		const submitValues = () => {
			if (!valuesFormRef.value) {
				return;
			}

			valuesFormRef.value.validate(valid => {
				if (valid) {
					valueLoading.value = true;
					const data: any = {
						...formData.value,
						...formValueData.value
					};
					ApiService.post(`EnumerationValues`, data)
						.then(({ data }) => {
							console.log('add enumeration  value response', data);
							valueLoading.value = false;
							emit('getEnumerationDetails');
							Swal.fire({
								timer: 1500,
								text: 'Meta data Value has been successfully added!',
								icon: 'success',
								buttonsStyling: false,
								confirmButtonText: 'Ok, got it!',
								customClass: {
									confirmButton: 'btn btn-primary'
								}
							});
						})
						.catch(({ response }) => {
							console.log('add enumeration error', response);
							Swal.fire({
								text: 'Sorry, looks like there are some errors detected, please try again.',
								icon: 'error',
								buttonsStyling: false,
								confirmButtonText: 'Ok, got it!',
								customClass: {
									confirmButton: 'btn btn-primary'
								}
							});
						});
				} else {
					Swal.fire({
						text: 'Sorry, looks like there are some errors detected, please try again.',
						icon: 'error',
						buttonsStyling: false,
						confirmButtonText: 'Ok, got it!',
						customClass: {
							confirmButton: 'btn btn-primary'
						}
					});
					return false;
				}
			});
		};

		const deleteEnumerationValue = id => {
			ApiService.delete(`EnumerationValues/${id}`)
				.then(({ data }) => {
					console.log('data', data);
					emit('getEnumerationDetails');
					Swal.fire({
						timer: 1500,
						text: 'Data has been successfully deleted!',
						icon: 'success',
						buttonsStyling: false,
						confirmButtonText: 'Ok, got it!',
						customClass: {
							confirmButton: 'btn btn-primary'
						}
					});
				})
				.catch(({ response }) => {
					console.log('response', response);
					Swal.fire({
						text: 'Sorry, looks like there are some errors detected, please try again.',
						icon: 'error',
						buttonsStyling: false,
						confirmButtonText: 'Ok, got it!',
						customClass: {
							confirmButton: 'btn btn-primary'
						}
					});
				});
		};

		return {
			formData,
			formValueData,
			rules,
			valuesRules,
			submit,
			submitValues,
			formRef,
			valuesFormRef,
			loading,
			valueLoading,
			enumValueData,
			editenumerationModalRef,
			deleteEnumerationValue
		};
	}
});
