
import { defineComponent, ref, watch } from 'vue';
import { hideModal } from '@/core/helpers/dom';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import ApiService from '@/core/services/ApiService';
export default defineComponent({
	name: 'edit-enumeration-modal',
	props: {
		selectedEnumerationValue: Object
	},
	components: {},
	setup(props, { emit }) {
		const formRef = ref<null | HTMLFormElement>(null);
		const editenumerationModalRef = ref<null | HTMLElement>(null);
		const loading = ref<boolean>(false);
		const formData = ref(props.selectedEnumerationValue);

		const rules = ref({
			enumValue: [
				{
					required: true,
					message: 'Meta data value is required',
					trigger: 'change'
				}
			],
			sortOrder: [
				{
					required: true,
					message: 'Sort Order is required',
					trigger: 'change'
				}
			]
		});

		const submit = () => {
			if (!formRef.value) {
				return;
			}

			formRef.value.validate(valid => {
				if (valid) {
					loading.value = true;
					const data: any = formData.value;
					ApiService.put(
						`EnumerationValues/${props.selectedEnumerationValue?.id}?enumerationValueId=${props.selectedEnumerationValue?.id}`,
						data
					)
						.then(({ data }) => {
							console.log('update enumeration  value response', data);
							loading.value = false;
							emit('getEnumerationDetails');
							Swal.fire({
								timer: 1500,
								icon: 'success',
								text: 'Meta data Value has been successfully updated!',
								buttonsStyling: false,
								confirmButtonText: 'Ok, got it!',
								customClass: {
									confirmButton: 'btn btn-primary'
								}
							}).then(() => {
								hideModal(editenumerationModalRef.value);
							});
						})
						.catch(({ response }) => {
							console.log('update enumeration value error', response);
							Swal.fire({
								text: 'Sorry, looks like there are some errors detected, please try again.',
								icon: 'error',
								buttonsStyling: false,
								confirmButtonText: 'Ok, got it!',
								customClass: {
									confirmButton: 'btn btn-primary'
								}
							});
						});
				} else {
					Swal.fire({
						text: 'Sorry, looks like there are some errors detected, please try again.',
						icon: 'error',
						buttonsStyling: false,
						confirmButtonText: 'Ok, got it!',
						customClass: {
							confirmButton: 'btn btn-primary'
						}
					});
					return false;
				}
			});
		};

		return {
			formData,
			rules,
			submit,
			formRef,
			loading,
			editenumerationModalRef
		};
	}
});
